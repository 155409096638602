import { PayloadAction } from '@reduxjs/toolkit'
import { includes, isEqual } from 'lodash'
import { State } from 'src/state/case/slice'
import { DOCUMENT_DIRECTIVE_NAME } from 'src/constants/directiveNames'
import { FIXED_DOCUMENT_INFOS } from 'src/constants/stepNames'
import { TCountry } from 'src/typings/case'

const appendString = (arr: string[], value: string): string[] => {
  if (!includes(arr, value)) {
    return [...arr, value]
  }

  return arr
}
const removeString = (arr: string[], value: string): string[] => {
  if (includes(arr, value)) {
    const newArr = [...arr]
    return newArr.filter((step) => step !== value)
  }
  return arr
}

/**
 * @ignore
 */
const resetStep = (state: State, action: PayloadAction<string>) => {
  const { payload: stepName } = action
  state.finishedSteps = removeString(state.finishedSteps, stepName)
  state.declinedSteps = removeString(state.declinedSteps, stepName)
}

/**
 * @ignore
 */
const finishStep = (state: State, action: PayloadAction<string>) => {
  const { payload: stepName } = action
  state.finishedSteps = appendString(state.finishedSteps, stepName)
  state.declinedSteps = removeString(state.declinedSteps, stepName)
}

/**
 * @ignore
 */
const declineStep = (state: State, action: PayloadAction<string>) => {
  const { payload: stepName } = action
  state.declinedSteps = appendString(state.declinedSteps, stepName)
  state.finishedSteps = removeString(state.finishedSteps, stepName)
}

const updateDocuSignEnvelopesStatus = (state: State, action: PayloadAction<{ [key: string]: string }>) => {
  const { payload } = action
  const documentInfos = state.stepObj[DOCUMENT_DIRECTIVE_NAME].documentInfos
  documentInfos.forEach((doc) => {
    if (doc.docuSignEnvelopId && payload[doc.docuSignEnvelopId]) {
      doc.isDocuSignCompleted = doc.isDocuSignCompleted || payload[doc.docuSignEnvelopId] === 'completed'
    }
  })
  const newStepObj = {
    ...state.stepObj,
    [DOCUMENT_DIRECTIVE_NAME]: {
      ...state.stepObj[DOCUMENT_DIRECTIVE_NAME],
      documentInfos
    }
  }
  if (!isEqual(state.stepObj, newStepObj)) {
    state.stepObj = newStepObj
  }
}

const setupForSingPass = (state: State) => {
  const documentInfos = state.stepObj[DOCUMENT_DIRECTIVE_NAME].documentInfos
  FIXED_DOCUMENT_INFOS.forEach((doc) => {
    if (!documentInfos.some((doc2) => doc2.documentType === doc.documentType)) {
      documentInfos.push(doc)
    }
  })
  state.stepObj = {
    ...state.stepObj,
    [DOCUMENT_DIRECTIVE_NAME]: {
      ...state.stepObj[DOCUMENT_DIRECTIVE_NAME],
      documentInfos
    }
  }
}

/**
 * @ignore
 */
const updateCountries = (state: State, action: PayloadAction<{ countries: TCountry[] }>) => {
  const { payload } = action

  if (payload?.countries) {
    state.countries = payload.countries
  } else {
    state.countries = [{ id: 0, name: '' }]
  }
}

/**
 * @ignore
 */
const updateNationalities = (state: State, action: PayloadAction<{ nationalities: TCountry[] }>) => {
  const { payload } = action

  if (payload?.nationalities) {
    state.nationalities = payload.nationalities
  } else {
    state.nationalities = [{ id: 0, name: '' }]
  }
}

/**
 * @ignore
 */
const updateIsUploadingDetails = (state: State, action: PayloadAction<boolean | undefined>) => {
  const { payload } = action
  state.isUploadingDetails = !!payload
}

/**
 * @ignore Back button clicked timestamp
 */
const updateBackButtonClickedTimestamp = (state: State) => {
  state.backBtnClickedTime = Date.now()
}

const caseReducers = {
  finishStep,
  declineStep,
  resetStep,
  updateDocuSignEnvelopesStatus,
  setupForSingPass,
  updateCountries,
  updateNationalities,
  updateIsUploadingDetails,
  updateBackButtonClickedTimestamp
}

export default caseReducers
