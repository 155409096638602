import { createSlice } from '@reduxjs/toolkit'
import { getSettings } from 'src/state/app/actions'
import { login } from 'src/state/user/actions'
import { createBlobInContainer, submitCase, updateIndividual, uploadFile } from 'src/state/case/actions'
import storage from 'src/utils/storage'
import { BrandingAgreementLinks } from 'src/typings/user'
import { TGlobalLoading } from 'src/typings/app'
import { ENTITY_PATHNAME } from 'src/constants/app'
import entityRequest from 'src/state/rtkQueries/entityRequest'
import reducers from './reducers'

export interface State {
  apiUrl?: string
  uploadProcessDone: boolean
  entityPath: string
  isDocumentValidation: boolean
  frontendHostName?: string
  signalRUrl?: string
  isError?: boolean
  error?: Error
  brandingAgreementLinks: BrandingAgreementLinks
  isIndividual: boolean
  isMyInfoIndividual: boolean
  isEntity: boolean
  isMyInfoEntity: boolean
  globalLoading: TGlobalLoading
  globalLoadingMessage?: string
  documentRequestCount: TGlobalLoading
  documentRequestCountTimeout?: NodeJS.Timeout
}

const initialState = {
  entityPath: storage.getEntityPath(),
  uploadProcessDone: false,
  isDocumentValidation: storage.getEntityPath() === ENTITY_PATHNAME.entityValidation,
  brandingAgreementLinks: storage.getBrandingAgreementLinks(),
  isEntity: !!storage.getLoginData()?.companyDetail,
  isMyInfoEntity: !!storage.getLoginData()?.companyDetail?.isMyInfo,
  isIndividual: !!storage.getLoginData()?.individualDetail,
  isMyInfoIndividual: !!storage.getLoginData()?.individualDetail?.isMyInfo,
  globalLoading: {},
  documentRequestCount: {}
} as State

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers,
  extraReducers(builder) {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        const { payload } = action
        const apiUrl = payload.apiUrl
        if (apiUrl && storage.getAPIServerUrl() !== apiUrl) {
          storage.setAPIServerUrl(apiUrl)
          window.history.go(0)
        }
        state.apiUrl = apiUrl
        state.frontendHostName = payload.frontendHostName
        const signalRUrl = payload.signalRUrl
        state.signalRUrl = signalRUrl
        storage.setSignalrServerUrl(signalRUrl)
      })
      .addCase(getSettings.rejected, (state, action) => {
        throw new Error(action.error as string)
      })
      .addCase(login.fulfilled, (state, action) => {
        const { payload } = action
        state.isEntity = !!payload.companyDetail
        state.isMyInfoEntity = !!payload.companyDetail?.isMyInfo
        state.isIndividual = !!payload.individualDetail
        state.isMyInfoIndividual = !!payload.individualDetail?.isMyInfo
        if (payload.branding) {
          state.brandingAgreementLinks = payload.branding
          storage.setBrandingAgreementLinks(payload.branding)
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error as Error
      })
      .addCase(submitCase.rejected, (state, action) => {
        throw new Error(action.error?.message ?? (action.error as string))
      })
      .addCase(createBlobInContainer.rejected, (state, action) => {
        throw new Error(action.error as string)
      })
      .addCase(uploadFile.rejected, (state, action) => {
        throw new Error(action.error as string)
      })
      .addCase(updateIndividual.rejected, (state, action) => {
        if (action.payload) {
          throw action.payload
        }
        throw new Error(action.error as string)
      })
      .addCase(submitCase.fulfilled, (state, action) => {
        state.uploadProcessDone = true
      })
      .addMatcher(entityRequest.endpoints.submit.matchFulfilled, (state) => {
        state.uploadProcessDone = true
      })
  }
})

export default appSlice
export const actions = appSlice.actions
