import { useMemo } from 'react'
import { split, replace } from 'lodash'
import { useLocation } from 'react-router-dom'
import storage from 'src/utils/storage'
import { BRANDS } from 'src/constants/app'

interface UseQueryReturn {
  brand: string
  isPayoneer: boolean
  isAscentium?: boolean
}

const getBrandFromHost = (host: string) => {
  if (!host) {
    return ''
  }
  // eg: prod urls: https://knowyourcustomer.atlassian.net/browse/KWA-17848?focusedCommentId=2001152
  const brandNames = Object.values(BRANDS).map((b) => b.toLowerCase())
  const hostnameParts = host.toLowerCase().split('.')

  for (let i = 0; i < hostnameParts.length; i++) {
    const part = hostnameParts[i]
    if (BRANDS[part]) {
      return BRANDS[part]
    }

    const index = brandNames.findIndex((brand) => part.includes(brand))

    if (index !== -1) {
      return brandNames[index]
    }
  }

  return ''
}

export default function useQuery(): UseQueryReturn {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const host = window.location.host
  let brand: string | undefined | null = query.get('brand')
  if (!brand) {
    // eg: https://uploadportal-dev-grab.knowyourcustomer.com/individual?step=identity,
    // the subdomainBrand should be grab
    let subdomainBrand = ''
    if (host.indexOf('payoneer') > -1) {
      subdomainBrand = 'payoneer'
    } else if (host.indexOf('bplkyc') > -1) {
      subdomainBrand = 'bplkyc'
    } else {
      subdomainBrand = replace(split(host, '.')[0], /uploadportal|dev|stage|http(s)|\/\/|:|-/g, '')
    }
    brand = BRANDS[subdomainBrand] || getBrandFromHost(host) || storage.getBrand()
  } else {
    brand = BRANDS[brand] ?? brand
  }
  if (!brand) {
    brand = 'kyc'
  }

  storage.setBrand(brand)
  return {
    brand,
    isAscentium: brand === 'ascentium',
    isPayoneer: brand === 'payoneer'
  }
}
